var refreshPoitGetplus = function (json) {
    var poinFormated = nFormatter(json.data.points_balance, 1);
    var poinFull = numberWithCommas(getFlooredFixed(parseInt(json.data.points_balance),0));
    $('#userGetplus').attr('title', 'My Getplus : ' + poinFull);
    $('#getPlusPoin').html(poinFormated);
    $('#iconGetplus-mobile-container .gplusPoinMenu').html(poinFormated);
    if(json.status == 600) $('#profile-update-message').html('<span class="w20h20 iconGetplus"></span> Anda mendapatkan <b>'+json.data.points_earned+'</b> poin').removeClass('failure').addClass('getplusBg').fadeIn('fast').delay( 8000 ).slideUp( 400 );
}
var refreshPoinTriviaGetplus = function(json) {
  var balances = json.points_earned;
  if(balances > 0){
    var poinFormated = nFormatter(json.points_balance, 1);
    var poinFull = numberWithCommas(getFlooredFixed(parseInt(json.points_balance),0));
    $('#userGetplus').attr('title', 'My Getplus : ' + poinFull);
    $('#getPlusPoin').html(poinFormated);
    $('#iconGetplus-mobile-container .gplusPoinMenu').html(poinFormated);
    $('#profile-update-message').html('<span class="w20h20 iconGetplus"></span> Anda mendapatkan <b>'+json.points_earned+'</b> poin').removeClass('failure').addClass('getplusBg').fadeIn('fast').delay( 8000 ).slideUp( 400 );
  } 
}
var changeUrlProfileGetplus = function (json) {
    $('#userGetplus, #iconGetplus-mobile-container a').attr('href', '/@'+json.data.username+'/my-getplus')
}
var cookiePromo = $.cookie('promopage');
var showPromoBottomGetPlus = function (isLoginUser) {
    if(cookiePromo === null){
        $.cookie('promopage', true, { expires: 1 , path: '/' });
        cookiePromo = 'true';
    }
    if(isLoginUser){
        if(isAccountVerified){
            if($('#Join_ID_GTM').length){
                $('#Join_ID_GTM')
                    .html('Aktivasi Getplus')
                    .on('click', function(){
                        showModalActivation()
                    })
            }
        }else{
            if($('#Join_ID_GTM').length){
                $('#Join_ID_GTM')
                    .html('Email Verification')
                    .on('click', function(){
                        showVerificationPopUp()
                    })
            }
        }

    }else{
        if($('#Join_ID_GTM').length){
            $('#Join_ID_GTM')
                .attr('href', '/register?page=/getplus-info')
        }
    }
    if(!$('#Join_ID_GTM').length){
        drawPromo(isLoginUser,isAccountVerified)
    }
}
function drawButtonJoin(){
    if($('#Join_ID_GTM').length){
        $('#Join_ID_GTM')
            .html('My Getplus')
            .click(function(){
                window.location = $('#view-profil').attr('href')+'/my-getplus'
            })
    }
}
function drawPromo(isLoginUser,isVerified){
    if(cookiePromo == 'true'){
        var setUrl = '/getplus-info';
        var setFunc = '';
        var setText = 'INFO GETPLUS'
        if(isLoginUser){
            if(isVerified){
                setUrl = 'javascript:void(0)';
                setFunc = 'onclick="showModalActivation()"'
                setText = 'AKTIVASI GETPLUS'
            }else{
                setUrl = 'javascript:void(0)';
                setFunc = 'onclick="showVerificationPopUp()"'
                setText = 'EMAIL VERIFICATION'
            }
        }
        cookiePromo = 'false';
        var append = ''
        append += '<div id="promoGetPlus">'
        append += '<div class="promo-bottom-getplus-spacer"></div>'
        append += '<div class="promo-bottom-getplus">'
        append += '<div class="promo-bottom-getplus-close-wrap">'
        append += '<div class="promo-bottom-getplus-close-container">'
        append += '<a href="javascript:void(0)" class="promo-bottom-getplus-close-button" onclick="closePromo()"></a>'
        append += '</div></div>'
        append += '<div class="promo-bottom-getplus-text">Banyak Deals Menarik di GetPlus,&nbsp;</div>'
        append += '<div class="promo-bottom-getplus-text">Pastikan Kamu Sudah Register!</div>'
        append += '<a href="'+setUrl+'" class="promo-bottom-getplus-button" '+setFunc+'>'+setText+'</a>'
        append += '</div>'
        append += '</div>'
        $('body').append(append)
    }
}
function closePromo(){
    $.cookie('promopage', false, { expires: 1, path: '/' });
    cookiePromo = false;
    $('#promoGetPlus').remove()
}
var timedOnPage = false,
    scrolledPage = false,
    wordcount = 0,
    pageTime = 50000,
    percentPage = 50,
    articleId = $('#article-content').length ? $('.title').attr('data-id') : '',
    dateRequest = $('#article-content').length ? $('#article-content').attr('data-curent_date') : '',
    paramArticleRead = {'article_id': articleId};
    if(typeof $('#article-content').attr('data-wordcount') !== 'undefined'){
        wordcount = $('#article-content').length ? (parseInt($('#article-content').attr('data-wordcount'))*10) : 0;
    }
    if(wordcount < 50000) pageTime = wordcount;
    if(wordcount < 100 ) pageTime = 10000;
function scrollPageGetPlus(){
    setTimeout(function(){
        timedOnPage = true;
        if(scrolledPage){
            if(scrolled == 'true'){
                scrolled = 'false'
                readArticleRewards()
            }
        }
    }, pageTime);
}
$(window).on('scroll', function(){
    if($('#article-content').length){
        if(scrolled == 'true'){
            if($(document).scrollTop() > ( parseInt($('#article-content').height()) * ( percentPage / 100) ) ){
                scrolledPage = true;
                if(timedOnPage){
                    scrolled = 'false'
                    readArticleRewards()
                }
            }
        }
    }
})
function readArticleRewards(){
    if(is_getplus_earn_point_call_permitted){
        actionWebApiRestrict('/v1/article/read', paramArticleRead, 'POST').done(function(json) {
            if(json.status != "803"){
                if(typeof json.data.points_balance !== "undefined") {
                    refreshPoitGetplus(json);
                    $.cookie(hashReadArticle, false, { expires: 1 });
                }
            }
        })
    }
}
function shareArticlePageGetPlus(platform){
    var articleId = $('.title').attr('data-id');
    var dateRequest = $('#article-content').attr('data-curent_date');
    var hashShare = usernameAfterLogin+'-'+articleId+'-'+'S'+'-'+dateRequest;
    var shared = $.cookie(hashShare);
    if(is_getplus_earn_point_call_permitted){
        if($.cookie(hashShare) == null){
            $.cookie(hashShare, true, { expires: 1 , path: '/' });
            shared = 'true';
        }
        var param = {'article_id': articleId, 'channel': platform};
        if(shared){
            actionWebApiRestrict('/v1/article/share_with_rewards', param, 'POST').done(function(json) {
                if(json.status != "803"){
                    if(typeof json.data.points_balance !== "undefined") {
                        refreshPoitGetplus(json);
                        $.cookie(hashShare, false, { expires: 1 , path: '/' });
                    }
                }
            })
            
        } else {
           actionWebApiRestrict('/v1/article/share', param, 'POST')
        }
    }else{
        actionWebApiRestrict('/v1/article/share', param, 'POST')
    }
    
}
function joinGetplus(){
    if(is_getplus_register_member_call_permitted){
        if(activation){
            actionWebApiRestrict('/v1/getplus/join', {}, 'POST').done(function(json) {
                if(json.status == '600'){
                    if(!json.data.error){
                        window.location = $('#view-profil').attr('href')+'/my-getplus'
                    }
                }else{
                    $('#profile-update-message').html('<span class="w20h20 iconGetplus"></span> Terjadi Kesalahan Message Error: '+json.ErrorCode+' '+json.ErrorDescription).addClass('failure').removeClass('getplusBg').fadeIn('fast').delay( 5000 ).slideUp( 400 );
                }
            }).fail(function(){
                $('#profile-update-message').html('<span class="w20h20 iconGetplus"></span> Terjadi Kesalahan Message Error: fail').addClass('failure').removeClass('getplusBg').fadeIn('fast').delay( 5000 ).slideUp( 400 );
                msgErrReg = '<div class="modal-getplus-detail-content"><h3>Aktivasi GetPlus</h3>'
                msgErrReg += '<div class="getplus-popup-text">'
                msgErrReg +=     '<span>Terjadi kesalahan, coba beberapa saat lagi!</span>'
                msgErrReg += '</div>'
                msgErrReg += '<div class="button-getplus">'
                msgErrReg +=     '<span class="cancel-getplus" onclick="hideGetplusActivation()">Ok</span>'
                msgErrReg += '</div></div>'
                $('.modal-getplus-detail').html(msgErrReg)
            })
        }
    }
}
function showModalActivation(){
    $('.modal-getplus-overlay, .modal-getplus-container').removeClass('collapse')
    $('#promoGetPlus').addClass('collapse')
}
function hideGetplusActivation(){
    $('.modal-getplus-overlay, .modal-getplus-container').addClass('collapse')
    $('#promoGetPlus').removeClass('collapse')
    $('.getplus-activation__checklist').removeClass('checked')
    $('#aktivasiGetplus').addClass('disabled')
    activation = false;
}
function showGetplusTnc(){
    $('.modal-getplus-tnc-container').removeClass('collapse')
}
function approveGetplusTnc(){
    $('.modal-getplus-tnc-container').addClass('collapse');
    $('.getplus-activation__checklist').addClass('checked')
    $('#aktivasiGetplus').removeClass('disabled')
    activation = true;
}
function hideGetplusTnc(){
    $('.modal-getplus-tnc-container').addClass('collapse');
}
var activation = false;
function activationCheck(){
    if($('.getplus-activation__checklist').hasClass('checked')){
        $('.getplus-activation__checklist').removeClass('checked')
        $('#aktivasiGetplus').addClass('disabled')
        activation = false;
    }else{
        $('.getplus-activation__checklist').addClass('checked')
        $('#aktivasiGetplus').removeClass('disabled')
        activation = true;
    }
}
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function getFlooredFixed(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
}
function nFormatter(num, digits) {
  num = num.substr(0, num.indexOf('.'))
  var si = [
    { value: 1, symbol: "" },
    { value: 4, symbol: "K" },
    { value: 7, symbol: "M" },
    { value: 10, symbol: "G" },
    { value: 13, symbol: "T" },
    { value: 16, symbol: "P" },
    { value: 19, symbol: "E" }
  ];
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num.length >= si[i].value) {
      break;
    }
  }
  var beforeDecimalLength = num.length - si[i].value + 1;
  var afterDecimal = num.substring(beforeDecimalLength, beforeDecimalLength + digits);
  var afterDecimalFormatted = '';
  var isAllZeros = true;
  for(var j = 0; j< afterDecimal.length; j++) {
    if(afterDecimal.charAt(j) !== '0') {
        isAllZeros = false;
        break;
    }
  }
  if(!isAllZeros) {
    afterDecimalFormatted = '.' + afterDecimal;
  }
  return num.substring(0, beforeDecimalLength) + afterDecimalFormatted + si[i].symbol;
}
